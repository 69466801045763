import {
  Anchor,
  Box,
  Button,
  PasswordInput,
  Text,
  TextInput
} from '@mantine/core';
import { useForm } from '@mantine/form';
import check from 'check-types';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

import { Paths } from '@/core/constants/routes.constants';
import { useAuthUser } from '@/core/hooks/query-hooks/use-auth-user/use-auth-user';
import { useFeatureFlags } from '@/core/hooks/use-feature-flags/use-feature-flags';
import AuthPageLayout from '@/core/layouts/auth-page-layout/auth-page-layout';
import MinimalLayout from '@/core/layouts/minimal-layout/minimal-layout';
import { useFeatureFlagsStore } from '@/core/stores/feature-flags-store/feature-flags.store';
import { Credentials, SocialLoginProps } from '@/core/types/auth.types';
import { isValidEmail } from '@/core/utils/is-valid-email/is-valid-email';

export { getServerSideProps } from '@/core/get-server-side-props/get-sign-in-server-side-props';

const SignIn = ({
  googleEnabled,
  githubEnabled,
  oktaEnabled,
  activeDirectoryEnabled,
  customSSOEnabled,
  callbackUrl
}: SocialLoginProps) => {
  const router = useRouter();
  const { login } = useAuthUser();
  const isAirgapped = useFeatureFlagsStore((state) => state.airgapped);

  const { hideCredentialsLogin } = useFeatureFlags();
  const emailInput = useRef<HTMLInputElement>(null);

  const form = useForm({
    initialValues: {
      email: '',
      password: ''
    },
    validate: {
      email: (value) =>
        isValidEmail(value) ? null : 'Please enter valid email',
      password: (value) =>
        check.not.emptyString(value) ? null : 'Please enter your password'
    }
  });

  const handleSubmit = async (credentials: Credentials) => {
    login.mutate(credentials);
  };

  useEffect(() => {
    router.prefetch(Paths.SIGN_UP);
    router.prefetch(Paths.ROOT);
    router.prefetch(Paths.FORGOT_PASSWORD);
    emailInput.current?.focus?.();
  }, []);

  const showCredentialsLogin = !hideCredentialsLogin.isEnabled;

  return (
    <AuthPageLayout
      displayRequestInvite
      isLogin
      activeDirectoryEnabled={activeDirectoryEnabled}
      callbackUrl={callbackUrl}
      customSSOEnabled={customSSOEnabled}
      githubEnabled={githubEnabled}
      googleEnabled={googleEnabled}
      oktaEnabled={oktaEnabled}
    >
      {showCredentialsLogin && (
        <form onSubmit={form.onSubmit(handleSubmit)}>
          <TextInput
            autoFocus
            required
            data-testid='login-username'
            label='Email'
            mt='xl'
            placeholder='example@address.com'
            ref={emailInput}
            {...form.getInputProps('email')}
          />
          <PasswordInput
            required
            data-testid='login-password'
            label='Password'
            mt='xs'
            placeholder='Enter 8 or more character'
            {...form.getInputProps('password')}
          />

          <Button
            fullWidth
            data-testid='login-btn'
            loading={login.isPending}
            mt='lg'
            size='md'
            type='submit'
          >
            Sign in
          </Button>

          <Box className='text-center' mt='lg'>
            {isAirgapped ? (
              <Text size='sm'>Forgot password? Contact admin to reset</Text>
            ) : (
              <Anchor c='brand.6' fw={600} href={Paths.FORGOT_PASSWORD}>
                Forgot password?
              </Anchor>
            )}
          </Box>
        </form>
      )}
    </AuthPageLayout>
  );
};

SignIn.getLayout = function getLayout(page: any) {
  return <MinimalLayout>{page}</MinimalLayout>;
};

export default SignIn;
